* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.hero-header {
  background: url('./hero_image.jpeg');
  text-align: center;
  width: 100%;
  height: auto;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  overflow: hidden;
  border-radius: 0 0 85% 85% / 30%;
  margin-bottom: 2rem;
}
.hero-header .overlay {
  width: 100%;
  height: 100%;
  padding: 50px;
  color: #FFF;
  text-shadow: 1px 1px 1px #333;
  background-image: linear-gradient(135deg, #34312D90 10%, #7E7F8390 100%);
}
h1 {
  
  font-size: 80px;
  margin-bottom: 30px;
}
h3, p {
 
  margin-bottom: 30px;
}
button {
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 50px;
  color: #333;
  background: #fff;
  margin-bottom: 50px;
  box-shadow: 0 3px 20px 0 #0000003b;
}
button:hover {
  cursor: pointer;
}
