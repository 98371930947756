.info-container {
    background-color: #7B0828;
    color: #a7adc6;
    padding: 20px;
    
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .info-content {
    text-align: center;
  }
  
  .info-content h2 {
    font-size: 24px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  
  .info-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }
  
  .info-item span {
    color: #8797af;
    margin-right: 10px;
    font-weight: 600;
  }
  
  .info-item a,
  .info-item p {
    color: #fff;
    margin: 0;
  }
  
  /* Media query for mobile devices (up to 767px width) */
  @media screen and (max-width: 767px) {
    .info-item {
      flex-direction: column;
    }
  
    .info-item span {
      margin-right: 0;
      margin-bottom: 5px;
    }
  
    .info-container {
      padding: 15px;
    }
  
    .info-content h2 {
      font-size: 20px;
    }
  }
  