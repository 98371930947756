.custom-component {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
  background-color: #900C3F; /* subtle red */
  max-width: 800px;
  margin: auto;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 25px;
  border: 1px solid #ff5733; /* border with a brighter red */
}

.custom-component:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.custom-component h2 {
  color: whitesmoke;
  font-size: 28px;
  text-transform: uppercase;
  font-weight: bold;
}

.custom-component img {
  border-radius: 5px 0 0 5px;
  width: 50%;
  object-fit: cover;
}

.custom-component p {
  font-size: 20px;
  margin-top: 1rem;
  color: whitesmoke;
}

.info_section {
  padding: 20px;
}

@media screen and (max-width: 767px) {
  .custom-component {
      flex-direction: column;
      text-align: center;
  }

  .custom-component img {
      border-radius: 5px 5px 0 0;
      width: 100%;
  }

  .info_section {
      width: 100%;
  }
}
